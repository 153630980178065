import { api, param2query } from "./api.service";

export const getAllDomains = (params) => {
  return api.get('logauditoria/listar?' + param2query(params));
};

export const getAllEntidades = () => {
  return api.get('logauditoria/buscar-entidades');
};

export default { getAllDomains };
