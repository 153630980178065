<template>
  <card-list>
    <div class="row mt-4">
      <div class="col-md-2 col-sm-4">
        <label class="d-inline-flex align-items-center">
          Mostrar:&nbsp;&nbsp;
          <b-form-select
            class="ms-2"
            v-model="paginacao.itensPorPagina"
            size="sm"
            :options="$tableItens"
            @input="getSearch"
          />
        </label>
      </div>
      <div class="col-md-3 col-12">
        <div class="d-inline-flex align-items-center">
          <label class=" d-inline-flex align-items-center">
            *Período:&nbsp;&nbsp;
            <date-picker
              name="periodoDe"
              type="month"
              format="MM/YYYY"
              v-model="filter.periodo"
              v-validate="{ required: true }"
              data-vv-as="Data de início do envio"
              :first-day-of-week="1"
              lang="pt-BR"
              :class="{
                'form-control is-invalid pb-0 pt-0 ps-0': isDisplayErrors('periodoDe'),
              }"
              @change="getSearch()"
            />
          </label>
        </div>
      </div>
      <div class="col-md-7 col-12">
        <label class="d-inline-flex align-items-center">
          E-mail usuário:
          <b-form-input
            v-model="filter.usuarioEmail"
            v-debounce:450="getSearch"
            class="ml-2 form-control form-control-sm ms-2 "
            @change="getSearch()"
          />
        </label>
      </div>
      <div class="col-md-9 col-12">
        <f-selectsearch inline
          label="*Entidade:"
          name="logEntidade"
          ref="logEntidade"
          v-model="filter.logEntidade"
          valueField="label"
          :cols="12"
          :searchFunction="listarEntidades"
          :searchAllOptions="true"
          :removeOptionsOnSelect="false"
          @select="selecionarEntidade"
          @change="getSearch()"
          v-validate="{ required: true }"
          data-vv-as="Entidade"
        />
      </div>
      <div class="col-md-3 col-12">
        <label class="d-inline-flex align-items-center">
          Id registro:
          <b-form-input
            v-model="filter.logPrimaryKey"
            v-debounce:450="getSearch"
            type="number"
            class="ml-2 form-control form-control-sm ms-2 "
            @change="getSearch()"
          />
        </label>
      </div>
    </div>
    <div class="table-responsive">
      <ui-table 
        :colunas="colunas"
        :items="items"
        :ordenacao-campo.sync="ordenacao.campo"
        :ordenacao-direcao.sync="ordenacao.direcao"
        :loading="loading"
        @force-refresh="getResults"
      >
        <template #actions="row">
          <b-button
            variant="outline-primary"
            small
            title="ver"
            v-authorize="'admLogsAuditoria'"
            @click="$router.push({name: 'sistema.log_auditoria.visualizar', params: { id: row.data.id } })"
          >
            <i class="uil uil-eye icon-size"></i>
          </b-button>
        </template>
      </ui-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <b-pagination
              v-model="paginacao.pagina"
              :total-rows="paginacao.totalRegistros"
              :per-page="paginacao.itensPorPagina"
              @input="getResults"
            />
          </ul>
        </div>
      </div>
    </div>
  </card-list>
</template>

<script>
import { getAllDomains, getAllEntidades } from "@/services/log-auditoria.service";
import UiTable from '@/components/Ui/UiTable.vue';
import CardList from '../../../../components/Ui/CardList.vue';
import FSelectsearch from '@/components/Form/SelectSearch';

export default {
  page: {
    title: (store, route) => {
      return route.meta.title;
    }
  },
  components: {
    CardList,
    UiTable,
    FSelectsearch
  },
  data() {
    return {
      filter: {
        periodo: '',
        usuarioEmail: '',
        logEntidadeId: null,
        logPrimaryKey: null,
      },
      colunas: [
        { key: "momento", label: "Data/hora", sortable: true },
        { key: "usuarioEmail", label: "E-mail", /*sortable: true*/ },
        { key: "evento", label: "Tipo Log", /*sortable: true*/ },
        { key: "classe", label: "Entidade", /*sortable: true*/ },
        { key: "primaryKey", label: "Id registro", /*sortable: true*/ },
        { key: "action", label: "Ações", thClass: 'text-center', tdClass: 'text-center text-nowrap' },
      ],
      items: [],
      paginacao: {
        pagina: 1,
        itensPorPagina: this.$tableItensDefault,
        totalPaginas: 1,
        totalRegistros: 1,
      },
      ordenacao: {
        campo: '',
        direcao: ''
      },
      loading: true
    };
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    log(action, data)  {
      console.log(action, data)
    },
    isDisplayErrors(tag) {
      return this.errors && this.errors.has(tag);
    },
    getParam() {
      let periodo = this.filter.periodo;
      if (periodo) {
        periodo = periodo.toISOString().split('T')[0]
      }

      return Object.assign(
        {},
        this.filter,
        {
          periodo,
          paginacao: this.paginacao,
          ordenacao: this.ordenacao,
        }
      );
    },
    getSearch() {
      this.paginacao.pagina = 1;
      this.getResults();
    },
    getResults() {
      this.loading = true;

      getAllDomains(this.getParam())
        .then(({ data }) => {
          this.items = data.resultado;
          this.paginacao.pagina = data.paginacao.pagina;
          this.paginacao.totalPaginas = data.paginacao.totalPaginas;
          this.paginacao.itensPorPagina = data.paginacao.itensPorPagina;
          this.paginacao.totalRegistros = data.paginacao.totalRegistros;
          this.ordenacao.campo = data.ordenacao.campo;
          this.ordenacao.direcao = data.ordenacao.direcao;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    listarEntidades() {
      return getAllEntidades();
    },
    selecionarEntidade(entidade) {
      this.filter.logEntidadeId = entidade.id;
      this.getSearch();
    },
  },
};
</script>